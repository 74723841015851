.inner-container {
    z-index: 200;
    background-color:rgb(245, 242, 236);
    width: 100vw;
    height: 100vh;
    transition: 1s;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    font-family: avenir;
    backdrop-filter: blur(20px);
    overflow-y: hidden;
    background-filter:blur(3px);
}

.main-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    position: absolute;
}

.container {
    
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;
}

.rows {
    min-height: 170vh;
    overflow-y: scroll;
}

.tests {
    display: flex;
    flex-direction: row;
}

.bg{
    background-color:#F4D57D;
    z-index: -1;
    right:0;
   
    border-top-left-radius: 50000px;
    border-bottom-left-radius: 50000px;
    position: absolute;
    width: 65vw;
    height: 150vh;
}

.side-graphic {
    width: 350px;
}

.rows-inner-container-left {
    margin-top:300px;
    display: flex;
    flex-wrap: wrap;
    max-width: 800px;
    flex-direction: column;
    margin-left: 100px;
    margin-bottom:500px;
}

.rows-inner-container-right {
    margin-top:400px;
    display: flex;
    flex-wrap: wrap;
    max-width: 800px;
    flex-direction: column;
    margin-right: 100px;
}


.type-button {
   font-weight: bold;
    font-family: avenir;
    border-radius: 20px;   
   width:150px;
   height: 60px;
   display: flex;
   justify-content: center;
   align-items: center;
    font-size: 15px;
    cursor: pointer;
    margin: 10px;
    color:rgb(255, 197, 24);
    background-color:  white;
    transition: 0.25s;

}

.type-button:hover {
    width:160px;
    background-color: rgb(255, 197, 24);
    transition: 0.25s;
    color:white;
}

.button-container {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 35vw;
    margin-left: 10%;
}

.image-container {
}

.button-container-row {
    width: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
}

.cancel-button {
    position: absolute;
    top: 40px;
    left: 40px;
    width:50px;
    cursor: pointer;
}